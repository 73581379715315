import React from 'react';
import AOS from 'aos';
import { Container, SvgIcon, Row, Col } from '../common';
import './index.scss'

const SocialLink = ({name, text, iconName, iconViewbox, link}) => {
    return (
        <div className='social-link'>
            <div className='left-icon'>
                <SvgIcon name={iconName} viewbox={iconViewbox} />
            </div>
            <div className='right-details'>
                <a href={link} target="_blank" rel="noreferrer noopener">
                    <h4>{name}</h4>
                    <SvgIcon name='arrow-link' viewbox='0 0 16 16' />
                </a>
                <p>{text}</p>
            </div>
        </div>    
    )
}

AOS.init();

const Community = () => {
    return (
        <div className='community-wrapper'>
            <section className='community-banner'>
                <Container className="community-row">
                    <Row>
                        <Col>
                            <h1>Meet the Community</h1>
                        </Col>
                    </Row>
                    <Row className='align-items-center'>
                        <Col sm='4'>
                            <SocialLink name="Twitter" 
                                iconName="twitter" 
                                iconViewbox="0 0 55.819 46.915" 
                                text="Stay updated about the latest happening in Comdex" 
                                link="https://twitter.com/Harbor_Protocol" />
                        </Col>
                        <Col sm='4'>
                            <SocialLink name="Telegram" 
                                iconName="telegram" 
                                iconViewbox="0 0 54.209 46.163" 
                                text="Connect, discuss & learn with other the community" 
                                link="https://t.me/Composite_Money" />
                        </Col>
                        <Col sm='4'>
                            <SocialLink name="Discord" 
                                iconName="discord" 
                                iconViewbox="0 0 65.002 49.503" 
                                text="Participate in discussions & report bugs in Comdex Ecosystem products" 
                                link="https://discord.gg/comdexofficial" />
                        </Col>
                        <Col sm='2'></Col>
                        <Col sm='4'>
                            <SocialLink name="Medium" 
                                iconName="medium" 
                                iconViewbox="0 0 58.333 48" 
                                text="Read blogs & articles about Comdex Ecosystem" 
                                link="https://medium.com/@Harbor_Protocol" />
                        </Col>
                        <Col sm='4'>
                            <SocialLink name="Github" 
                                iconName="github" 
                                iconViewbox="0 0 60 59.495" 
                                text="Keep in check with Comdex Developments" 
                                link="https://medium.com/@Harbor_Protocol" />
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default Community
