import React from 'react';
import AOS from 'aos';
import { Button } from 'antd';
import './Footer.scss';
import { Container, SvgIcon } from '../../common';

AOS.init({});

const Footer = () => {
  return (
    <div className="home-footer">
      <Container>
        <div className='footer-inner'>
          <div className='logo'>
            <SvgIcon name='harbor-logo' viewbox='0 0 144 51.17' />
            <span className='mx-2'>|</span>
            <Button className="terms-link" type="link" href="https://terms.comdex.one/Comdex_Harbor_Terms_and_Conditions.pdf" target="_blank" rel="noreferrer noopener">
              Terms & Conditions
            </Button>
          </div>
          <div className='build-with'>
              Built on
              <SvgIcon name='comdex-logo' viewbox='0 0 157.927 35.117' />
          </div>
          <div className='social-links'>
            <a href="https://twitter.com/Harbor_Protocol" target='_blank'>
              <SvgIcon name="twitter" viewbox="0 0 55.819 46.915" />
            </a>
            <a href="https://t.me/Composite_Money" target='_blank'>
              <SvgIcon name="telegram" viewbox="0 0 54.209 46.163" />
            </a>
            <a href="https://discord.gg/comdexofficial" target='_blank'>
              <SvgIcon name="discord" viewbox="0 0 65.002 49.503" />
            </a>
            <a href="https://medium.com/@Harbor_Protocol" target='_blank'>
              <SvgIcon name="medium" viewbox="0 0 58.333 48" />
            </a>
            <a href="https://github.com/comdex-official" target='_blank'>
              <SvgIcon name="github" viewbox="0 0 60 59.495" />
            </a>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Footer
