import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { defaultLayout } from "./layouts";

// Route Views
import Landing from "./components/Landing";
import CompositeMoney from "./components/CompositeMoney";
import Community from "./components/Community";

export default [
  {
    path: "/",
    exact: true,
    layout: defaultLayout,
    component: () => <Redirect to="/home" />
  },
  {
    path: "/home",
    layout: defaultLayout,
    component: Landing
  },
  {
    path: "/cmst",
    layout: defaultLayout,
    component: CompositeMoney
  },
  {
    path: "/community",
    layout: defaultLayout,
    component: Community
  }
];