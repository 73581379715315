import React from 'react';
import AOS from 'aos';
import { Container, SvgIcon, Row, Col } from '../common';
import './index.scss'

import CompositeLogo from '../../assets/images/composite-logo.png';

AOS.init();

const CompositeMoney = () => {
    return (
        <div className='composite-wrapper'>
            <section className='composite-banner'>
                <Container>
                    <Row className='align-items-center banner-row'>
                        <Col sm='8'>
                            <h1>Composite Money</h1>
                            <p>An overcollateralised IBC enabled stable coin built for the multichain. CMST’s mechanism is designed to be the most reliable model for stablecoins, which is censorship-resistant, permissionless, and decentralized.</p>
                        </Col>
                        <Col sm='4' className='right-col'>
                            <img src={CompositeLogo} alt={CompositeLogo} />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='whatharbor-section'>
                <Container>
                    <Row>
                        <Col>
                            <div className='main-heading'>
                                <h1><SvgIcon name='cmst-icon' viewbox='0 0 49.136 56' /> Composite Money - CMST</h1>
                            </div>
                            <ul className='common-list2'>
                                <li>
                                    <div className='list-inner'>
                                        <div className='upper-icon'>
                                            <SvgIcon name='overcollateralised-icon' viewbox='0 0 58 58' />
                                            <h3>Overcollateralised</h3>
                                        </div>
                                        <p>Every Composite stablecoin generated is backed by excess collateral, and hence its supply is always less than the value of collateral locked in Harbor Protocol</p>
                                    </div>
                                </li>
                                <li>
                                    <div className='list-inner'>
                                        <div className='upper-icon'>
                                            <SvgIcon name='ibcnative-icon' viewbox='0 0 67.113 67.113' />
                                            <h3>IBC Native</h3>
                                        </div>
                                        <p>From inception CMST is an IBC (Inter‐Blockchain Communication protocol) enabled stable coin</p>
                                    </div>
                                </li>
                                <li>
                                    <div className='list-inner'>
                                        <div className='upper-icon'>
                                            <SvgIcon name='earnyields-icon' viewbox='0 0 58 58' />
                                            <h3>Earn Yields</h3>
                                        </div>
                                        <p>Users can earn yields by locking it in Harbor protocol and on Commodo App</p>
                                    </div>
                                </li>
                                <li>
                                    <div className='list-inner'>
                                        <div className='upper-icon'>
                                            <SvgIcon name='decentralised-icon' viewbox='0 0 57 56.076' />
                                            <h3>Decentralised</h3>
                                        </div>
                                        <p>All decisions of the protocol functioning and parameter changes are voted on through governance which makes the protocol decentralized</p>
                                    </div>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default CompositeMoney