import React from 'react';
import AOS from 'aos';
import { Container, SvgIcon, Row, Col } from '../common';
import { HashLink } from 'react-router-hash-link';
import TermsModal from '../TermsModal';
import './index.scss'

// Import video
import Logo1 from '../../assets/images/logo.png';
import WhyHarborImg1 from '../../assets/images/whyharbor-img1.svg';
import WhyHarborImg2 from '../../assets/images/whyharbor-img2.svg';
import WhyHarborImg3 from '../../assets/images/whyharbor-img3.svg';
import CompositeLogo from '../../assets/images/composite-logo.png';

AOS.init();
const Landing = () => {
    return (
        <div className='lading-wrapper'>
            <section className='landing-banner'>
                <Container>
                    <Row className='align-items-center banner-row'>
                        <Col sm='8'>
                            <h1>Harbor Protocol</h1>
                            <p>Interchain Stablecoin Protocol on Comdex</p>
                            <div>
                                <TermsModal bannerLaunchButton />
                            </div>
                        </Col>
                        <Col sm='4' className='right-col'>
                            <img src={Logo1} alt={Logo1} />
                        </Col>
                    </Row>
                </Container>
                <HashLink className='scroll-link' to="#whatharborSectino">
                    <div className='scroll-link-inner'>
                        <div className='text'>
                            <p>Scroll</p>
                            <small>to discover more</small>
                        </div>
                        <div className='icon-right'>
                            <div></div>
                        </div>
                    </div>
                </HashLink>
            </section>
            <section className='whatharbor-section' id="whatharborSectino">
                <Container>
                    <Row>
                        <Col>
                            <div className='main-heading'>
                                <h1>What is Harbor Protocol?</h1>
                                <p>A publicly governed protocol enabling the minting of $CMST by collateralising interchain assets.</p>
                            </div>
                            <ul className='common-list'>
                                <li>
                                    <div className='list-inner'>
                                        <div className='upper-icon'>
                                            <div className='upper-icon-inner'>
                                                <SvgIcon name='mint' viewbox='0 0 36 36' />
                                            </div>
                                        </div>
                                        <div>
                                            <h3>Mint</h3>
                                            <p>Mint Composite ($CMST), an IBC-enabled stablecoin for the multichain</p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className='list-inner'>
                                        <div className='upper-icon'>
                                            <div className='upper-icon-inner'>
                                                <SvgIcon name='use' viewbox='0 0 51 36' />
                                            </div>
                                        </div>
                                        <div>
                                            <h3>Govern</h3>
                                            <p>Participate in the governance of the protocol</p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className='list-inner'>
                                        <div className='upper-icon'>
                                            <div className='upper-icon-inner'>
                                                <SvgIcon name='earn-reward' viewbox='0 0 37.335 52.546' />
                                            </div>
                                        </div>
                                        <div>
                                            <h3>Earn rewards</h3>
                                            <p>Claim rewards for locking tokens, including rebases, fees, and external incentives</p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className='list-inner'>
                                        <div className='upper-icon'>
                                            <div className='upper-icon-inner'>
                                                <SvgIcon name='auctions-icon' viewbox='0 0 48.524 50.505' />
                                            </div>
                                        </div>
                                        <div>
                                            <h3>Auctions</h3>
                                            <p>Participate in auctions to earn discounted collateral</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='whyharbor-section'>
                <Container>
                    <Row>
                        <Col>
                            <div className='main-heading'>
                                <h1>Why Harbor?</h1>
                            </div>
                            <ul className='common-list'>
                                <li>
                                    <div className='list-inner'>
                                        <div className='image-section'>
                                            <img src={WhyHarborImg1} alt={WhyHarborImg1} />
                                        </div>
                                        <p>Native Stablecoin for Cosmos Ecosystem</p>
                                    </div>
                                </li>
                                <li>
                                    <div className='list-inner'>
                                        <div className='image-section'>
                                            <img src={WhyHarborImg2} alt={WhyHarborImg2} />
                                        </div>
                                        <p>Access to $CMST liquidity on crypto assets</p>
                                    </div>
                                </li>
                                <li>
                                    <div className='list-inner'>
                                        <div className='image-section'>
                                            <img src={WhyHarborImg3} alt={WhyHarborImg3} />
                                        </div>
                                        <p>Lending {"&"} Liquidity Provisioning yield from $CMST</p>
                                    </div>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='composite-banner'>
                <Container>
                    <Row className='align-items-center banner-row'>
                        <Col sm='8'>
                            <h1>Composite Money</h1>
                            <p>An overcollateralised IBC enabled stable coin built for the multichain. CMST’s mechanism is designed to be the most reliable model for stablecoins, which is censorship-resistant, permissionless, and decentralized.</p>
                        </Col>
                        <Col sm='4' className='right-col'>
                            <img src={CompositeLogo} alt={CompositeLogo} />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='compositemoney-section'>
                <Container>
                    <Row>
                        <Col>
                            <div className='main-heading'>
                                <h1><SvgIcon name='cmst-icon' viewbox='0 0 49.136 56' /> Composite Money - CMST</h1>
                            </div>
                            <ul className='common-list'>
                                <li>
                                    <div className='list-inner'>
                                        <div className='upper-icon'>
                                            <SvgIcon name='overcollateralised-icon' viewbox='0 0 58 58' />
                                        </div>
                                        <h3>Overcollateralised</h3>
                                        <p>Every Composite stablecoin generated is backed by excess collateral, and hence its supply is always less than the value of collateral locked in Harbor Protocol</p>
                                    </div>
                                </li>
                                <li>
                                    <div className='list-inner'>
                                        <div className='upper-icon'>
                                            <SvgIcon name='ibcnative-icon' viewbox='0 0 67.113 67.113' />
                                        </div>
                                        <h3>IBC Native</h3>
                                        <p>From inception CMST is an IBC (Inter‐Blockchain Communication protocol) enabled stable coin</p>
                                    </div>
                                </li>
                                <li>
                                    <div className='list-inner'>
                                        <div className='upper-icon'>
                                            <SvgIcon name='earnyields-icon' viewbox='0 0 58 58' />
                                        </div>
                                        <h3>Earn Yields</h3>
                                        <p>Users can earn yields by locking it in Harbor protocol and on Commodo App</p>
                                    </div>
                                </li>
                                <li>
                                    <div className='list-inner'>
                                        <div className='upper-icon'>
                                            <SvgIcon name='decentralised-icon' viewbox='0 0 57 56.076' />
                                        </div>
                                        <h3>Decentralised</h3>
                                        <p>All decisions of the protocol functioning and parameter changes are voted on through governance which makes the protocol decentralized</p>
                                    </div>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default Landing