import React, { useState } from 'react';
import { Link, NavLink } from "react-router-dom";
import AOS from 'aos';
import { Menu, Drawer } from 'antd';
import MediaQuery from "react-responsive";
import { SvgIcon } from '../../common';
import './Navbar.scss';
import TermsModal from '../../TermsModal';

AOS.init({});

const items = [
  {
    label: <>Resources</>,
    key: 'item1',
    icon: <SvgIcon name="arrow-down" viewbox="0 0 15 8.381" />,
    children: [{ label: <div onClick={() => window.open('https://medium.com/@Harbor_Protocol', '_blank')}>Medium</div>, key: 'submenu-item-1' },
    { label: <div onClick={() => window.open('https://docs.harborprotocol.one/', '_blank')}>Docs</div>, key: 'submenu-item-2' },
    { label: <div onClick={() => window.open('https://forum.comdex.one/', '_blank')}>Forum</div>, key: 'submenu-item-3' }],
  },
  {
    label: <div onClick={() => window.open('https://app.harborprotocol.one/more/airdrop', '_blank')}>Claim Airdrop</div>, key: "item2"
  },
  {
    label: <NavLink to="/community">Community</NavLink>, key: "item3"
  }
];

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  return (
    <div className="home-header-wrapper">
      <div className="home-header" data-aos="fade" data-aos-delay="200">
        <div className="logo-wrapper">
          <div className="logo">
            <Link to="home">
              <SvgIcon name='harbor-logo' viewbox='0 0 144 51.17' />
            </Link>
          </div>
        </div>
        <div className="header-right">
          <MediaQuery minWidth={991}>
            <div className='header-menus'>
              <Menu items={items} mode='horizontal' />
            </div>
          </MediaQuery>
          <div>
            <TermsModal headerLaunchButton={true} />
          </div>
          <MediaQuery maxWidth={991}>
            <div className="menu-icon" onClick={showDrawer}>
              <SvgIcon name="menu" viewbox="0 0 14.1 11.5" />
            </div>
            <Drawer
              width={290}
              className="mobile-drawer"
              title={false}
              placement="right"
              onClose={onClose}
              open={open}
            >
              <Menu mode="vertical" items={items} />
            </Drawer>
          </MediaQuery>
        </div>
      </div>
    </div>
  );
}

export default Navbar
