import React from "react";
import PropTypes from "prop-types";

import { Navbar, Footer } from "../components/layout";

// Import video
import IntroVideo from '../assets/images/intro-video.mp4';

const DefaultLayout = ({ children, navbar, footer, props }) => (
  <React.Fragment>
    {!navbar && <Navbar />}
    <main>
      <div className="banner-video-wrapper">
        <video
          className='banner-video'
          playsInline
          loop
          autoPlay
          muted
          controls={false}
          alt="Harbor Video"
          src={IntroVideo}
        />
      </div>
      {children}
    </main>
    {!footer && <Footer />}
  </React.Fragment>
);

DefaultLayout.propTypes = {
  navbar: PropTypes.bool,
  footer: PropTypes.bool
};

DefaultLayout.defaultProps = {
  navbar: false,
  footer: false
};

export default DefaultLayout;